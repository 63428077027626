:root {
  --avatarUrl: "";
}

.container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-top: 120px;
  padding-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 48px;
  }
}

.container__inner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
