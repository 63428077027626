.tile_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tile_container__tile-consumptionTotal {
  width: auto;
  height: auto;
  padding: 22px;
  background-color: #1be7ff;
  margin: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
}

.tile_container__tile-carbonTotal {
  background-color: #06d6a0;
  width: auto;
  height: auto;
  padding: 22px;
  margin: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
}

.tile_container__tile__title {
  font-size: 18px;
  font-weight: 700;
  color: #22223b;
  margin-bottom: 8px;
}

.tile_container__tile__value {
  font-size: 24px;
  font-weight: 700;
  color: #22223b;
}
