.profile-photo {
  border-radius: 100%;
  min-width: 200px;
  min-height: 200px;
  background: var(--avatarUrl);
  background-size: cover;
}

p {
  text-align: center;
  color: #22223b;
  margin-top: 24px;
  font-weight: 500;
  font-size: 18px;
  padding: 0 24px;
}

h2 {
  background-color: #06d6a0;
  padding: 8px;
  border-radius: 4px;
  transform: rotate(-4deg);
  color: #FFF;
  font-weight: 700;
  font-size: 32px;
  margin-top: 24px;
}

.my-three-step-process {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
}

.my-three-step-process > span {
  background-color: #8B5CF6;
  color: #FFF;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 8px;
  font-weight: 500;
  font-size: 16px;

}

@media screen and (min-width: 768px) {
  p {
    text-align: center;
    color: #22223b;
    margin-top: 24px;
    width: 70vw;
  }
}
