@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  background: #F7FAFC;
}

a {
  text-decoration: none;
  color: #FFD200;
  font-weight: 700;
}
