.button-container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

button {
  border: none;
  font-weight: 700;
  font-size: 18px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  padding: 12px 18px;
  text-decoration: none;
}

button.github {
  background: #6e5494;
  color: #f1eef4;
}

button.shell-ai {
  background: #ddc9e3;
  color: #955ca6;
}

button.linkedin {
  background: #b5e7fe;
  color: #0077b5;
}

button.shellai {
  background: #10172A;
  color: #FFF;
}

button.passionfruit {
  background: #8B5CF6;
  color: #f3effe;
}

button.glyphgenie {
  background: #5B21B6;
  color: #efe9f8;
}

button.glyphgenie > img {
  border-radius: 100%;
  margin-right: 12px;
}

button > svg {
  margin-right: 12px;
}
